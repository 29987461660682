.transition {
  transition: 0.3s ease-in;
}

.videoWrapper {
  @extend .transition;
  position: absolute;
  background-size: cover;
  background-position: center;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1,
}

video {
  z-index: -1;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
}

.renderVideo {
  @extend .transition;
  opacity: 1;
}

.overlay {
  z-index: 1 !important;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.6)
}

@media (max-width: 850px) {
  video {
    left: -60%;
  }
}

@media (max-width: 500px) {
  video {
    left: -95%;
  }
}

@media (max-width: 400px) {
  video {
    left: -110%;
  }
}

@media (max-width: 350px) {
  video {
    left: -135%;
  }
}