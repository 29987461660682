@import "../../App.scss";

.CTAContainer {
  padding: 80px 0;
  background-color: #000;
  div {
    @extend .contentWidth, .drukWideRegular;
    margin: 0 auto;
    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #FFFFFF
    }
    a {
      text-decoration: none;
      button {
        @extend .drukWideRegular;
        display: block;
        margin: 35px auto;
        background-color: #E95656;
        padding: 16px 25px;
        border-radius: 25px;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.04em;
        color: #FFFFFF;
      }
    }
  }
}

@media (max-width: 600px) {
  .CTAContainer {
    padding: 40px 0;
    div {
      h2 {
        width: 85%;
        margin: 0 auto;
        font-size: 14px;
        line-height: 20px;
      }
    }
    a {
      button {
        font-size: 12px !important;
        line-height: 16px;
      }
    }
  }
}