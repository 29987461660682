body {
  height: 100%;
  width: 100%;
  max-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
}

button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

/* GLOBAL EXTENSIONS */
.flex {
  display: flex;
  justify-content: space-between;
}

.contentWidth {
  width: 60%;
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drukWideRegular {
  font-weight: 500;
  font-family: 'Druk Wide Regular'
}

@font-face {
  font-family: 'Graphik Regular';
  src: url('./static/fonts/Graphik/Graphik-Regular.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Medium';
  src: url('./static/fonts/Graphik/Graphik-Medium.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Wide Regular';
  src: url('./static/fonts/Druk\ Wide/DrukWide-Medium.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@media (max-width: 1150px) {
  .contentWidth {
    width: 70%;
  }
}

@media (max-width: 1000px) {
  .contentWidth {
    width: 75%;
  }
}

@media (max-width: 800px) {
  .contentWidth {
    width: 80%;
  } 
}


@media (max-width: 550px) {
  .contentWidth {
    width: 90%;
  } 
}