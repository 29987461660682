@import "../../App.scss";

a {
  text-decoration: none;
}

.resourcesContainer {
  background: linear-gradient(180deg, #E95656 0%, #E95656 100%);
  margin-top: 85px;
  padding: 35px 0 85px;
  h3 {
    @extend .contentWidth, .drukWideRegular;
    margin: 35px auto;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
  }
  .listContainer {
    @extend .contentWidth;
    background-color: #FFF;
    margin: 0 auto;
    .listItem {
      border-bottom: 1px solid #E95656;
      @extend .flex;
      .icon {
        @extend .flex;
        padding: 20px 15px;
        justify-content: center;
        border-right: 1px solid #E95656;
        width: 10%;
        img {
          height: 100%;
          width: 65px;
        }
      }
      .link {
        @extend .flex;
        align-items: center;
        padding: 0 20px;
        width: 90%;
        p {
          font-family: 'Graphik Regular';
          font-style: normal;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .resourcesContainer {
    padding: 1px 0 35px;
    h3 {
      font-size: 14px;
      line-height: 18px;
    }
    .listContainer {
      .listItem {
        .icon {
          img {
            height: 100%;
            width: 100%;
          }
        }
        .link {
          p {
            font-size: 13px;
          }
          img {
            height: 15px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .resourcesContainer {
    .listContainer {
      .listItem {
        .icon {
          width: 13%;
        }
        .link {
          padding: 0 15px;
          p {
            font-size: 12px;
            line-height: 14px;
          }
          img {
            height: 10px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}

/* MOBILE LANDSCAPE STYLES */
@media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) {
  .resourcesContainer {
    .listContainer {
      .listItem {
        .icon {
          width: 13%;
          img {
            height: 30px;
            height: 100%;
          }
        }
      }
    }
  }
}