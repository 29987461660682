@import "../../App.scss";

/* SHARED STYLES */
.slideButton {
  @extend .flex;
  margin: 0 auto;
  margin-top: 52px;
  align-items: center;
  border: 2px solid #000;
  background: #FFFFFF;
  border-radius: 25px;
  p {
    @extend .drukWideRegular;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: #1C1C1C;
    margin: 0;
    width: 100%;
  }
}

.front {
  z-index: 5;
}

.calendarWrapper {
  background-color: black;
  position: relative;
  z-index: 10000000;
}

/* CALENDAR STYLES */

.calendarContainer {
  position: relative;
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
  box-sizing: border-box; 
}

/* INDEX CALENDAR STYLES */

.indexCalendar {
  .indexCalendarContent {
    background: #FFFFFF;
    border: 6px solid #FFFFFF;
    @extend .flex;
    flex-direction: column;
    .indexHeader {
      padding: 5px 15px;
      @extend .flex;
      align-items: center;
      img {
        // margin-top: 10px;
        margin-left: 10px;
        height: 25px;
      }
      p {
        @extend .drukWideRegular;
        color: #000;
        font-size: 30px;
        line-height: 52px;
        text-align: right;
        letter-spacing: 0.03em;
      }
    }
    .indexBody {
      p {
        color: #000;
        font-family: 'Graphik Regular';
        font-size: 32px;
        line-height: 42px;
        width: 70%;
        margin: 0 auto;
        a {
          text-decoration: underline;
          font-family: 'Graphik Medium';
          color: #E95656;
        }
        span {
          text-decoration: line-through;
          text-decoration-color: #E95656;
          margin-right: 5px;
        }
      }
      button {
        @extend .slideButton;
        width: 272px;
        height: 50px;
        padding: 0 15px;
        img {
          transform: rotate(180deg);
          margin-left: 5px;
        }
      }
    }
  }
}

.calendar {
  cursor: grab;
  box-sizing: border-box;
  display: flex;
  min-height: 500px;
}

.dragging {
  cursor: grabbing !important;
}

.calendarContent {
  border: 6px solid #FFF;
  z-index: 99999999;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  @extend .flex;
  flex-direction: column;
  .header {
    @extend .flex, .front;
    img {
      margin-top: 20px;
      margin-left: 25px;
      height: 25px;
    }
    div {
      @extend .drukWideRegular;
      border: 6px solid #FFFFFF;
      box-sizing: border-box;
      padding: 10px 20px;
      position: relative;
      top: -5px;
      left: 5px;
      text-align: center;
      p {
        font-size: 26px;
        line-height: 34px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
      h2 {
        margin-top: -10px;
        font-size: 60px;
        line-height: 78px;
      }
    }
  }
  .body {
    @extend .front;
    p {
      font-family: 'Graphik Regular';
      font-size: 32px;
      line-height: 42px;
      width: 70%;
      margin: 0 auto;
      a {
        cursor: pointer;
        text-decoration: underline;
        font-family: 'Graphik Medium';
        color: #E95656;
      }
    }
  }
  .footer {
    @extend .front;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 25px 0;
    p {
      font-family: 'Graphik Regular';
      font-size: 20px;
      line-height: 22px;
      color: rgba(255, 255, 255, 0.65);
    }
    h3 {
      @extend .drukWideRegular;
      font-size: 48px;
      line-height: 63px;
      margin: 10px 0;
    }
    a {
      @extend .flex, .drukWideRegular;
      margin: 0 auto;
      margin-top: 20px;
      padding: 10px 15px;
      align-items: center;
      background: #E95656;
      border-radius: 25px;
      color: #FFF;
      img {
        margin-right: 10px;
      }
    }
  }
}

.indexCalendarContent, .calendarContent {
  width: 100%;
  margin: 40px 10px; 
}

.nextSlide, .prevSlide {
  opacity: 0.4;
}

.prevSlide {
  margin-left: 3.3%;
}

/* MODAL STYLES */

.modalWrapper {
  position: absolute;
  background-color: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  .modalContainer {
    border-radius: 4px;
    position: absolute;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color:  #FFF;
    max-width: 600px;
    .modalTop {
      padding: 30px 0;
      border-radius: 4px;
      h3 {
        @extend .drukWideRegular;
        text-align: center;
        color: #E95656;
      }
      .equationContainer {
        @extend .flex;
        margin: 40px 0 30px;
        justify-content: center;
        align-items: center;
        font-family: 'Graphik Medium';
        font-size: 18px;
        > div {
          div {
            width: 200px;
            border: 0.5px solid #000;
            margin: 8px auto;
          }
        }
        span {
          margin: 0 30px;
          font-size: 30px !important;
          font-family: 'Graphik Regular';
        }
        p {
          text-align: center;
        }
      }
    }

    .modalBottom {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 30px 0;
      p {
        font-family: 'Graphik Medium';
        text-align: center;
        color: #FFF;
      }
      img {
        margin: 30px 0 20px;
      }
      button {
        @extend .slideButton;
        background-color: #000;
        margin: 0 auto;
        p {
          color: #FFF;
          padding: 10px 20px;
        }
      }
      background-color: #E95656;
      @extend .flex;
      flex-direction: column;
    }
  }
}

/* ACTION ARROWS */
.actionContainer {
  @extend .flex;
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 0;
  width: 30px;
  justify-content: center;
  height: 100%;
}

#leftArrow {
  transform: rotate(180deg);
  left: 0;
}

#rightArrow {
  right: 0;
}

.swiperContainer {
  z-index: 1000;
  display: flex;
  color: #FFF;
}

/* END SLIDE STYLES */

.endSlide {
  opacity: 1 !important;
  cursor: grab;
  position: relative;
  height: auto;
  .contentContainer {
    @extend .absoluteCenter;
    > img {
      display: block;
      margin: 0 auto 50px;
      height: 100px;
    }
    p {
      width: 450px;
      font-family: 'Graphik Regular';
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
    button {
      @extend .slideButton;
      padding: 15px 20px;
      img {
        margin-right: 15px;
      }
    }
  }
}

/* SHARE FOOTER STYLES */

.shareFooter {
  z-index: 1;
  padding: 25px 0;
  p {
    margin: 0 auto;
    width: 550px;
    font-family: 'Graphik Regular';
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFF
  }
  a {
    text-decoration: none;
    button {
      @extend .flex, .drukWideRegular;
      margin: 0 auto;
      margin-top: 20px;
      padding: 10px 15px;
      align-items: center;
      background: #E95656;
      border-radius: 25px;
      color: #FFF;
      img {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 2300px) {
  .calendarContent, .indexCalendarContent {
    border-width: 5px;
    .header {
      div {
        border-width: 5px;
        top: -4px;
        left: 4px;
        p {
          font-size: 20px;
          line-height: 28px;
        }
        h2 {
          margin-top: -10px;
          font-size: 35px;
          line-height: 45px;
        }
      }
    }
    .body {
      p {
        width: 650px;
        font-size: 26px;
        line-height: 30px;
      }
    }
    .indexBody {
      > p:first-of-type {
        margin-top: 75px;
        width: 650px;
        font-size: 26px;
        line-height: 30px;
      }
    }
    .footer, .indexFooter {
      p {
        font-size: 16px;
        line-height: 18px;
      }
      h3 {
        font-size: 40px;
        line-height: 53px;
        margin: 10px 0;
      }
    }
  }
}

@media (max-width: 825px) {
  /* SHARED */
  .slideButton {
    width: auto;
    img {
      align-self: center;
      height: 10px;
    }
    p {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
  
  .calendar {
    padding: 15px 0;
  }

  /* SHARED CALENDAR CONTENT */
  .indexCalendarContent, .calendarContent {
    margin: 5px !important;
    .footer {
      p:last-of-type, p:first-of-type {
        margin: 0 !important;
      }
    }
  }

  /* MODAL STYLES */

  .modalWrapper {
    .modalContainer {
      width: 85%;
      .modalTop {
        h3 {
          font-size: 18px;
        }
        .equationContainer {
          font-size: 16px;
          > div {
            div {
              width: 150px !important;
            }
          }
          span {
            font-size: 25px !important;
            margin: 0 20px;
          }
        }
      }
      .modalBottom {
        p {
          font-size: 14px;
        }
      }
    }
  }


  /* ACTION ARROWS */
  .actionArrow {
    display: none;
  }


  /* INDEX SLIDE STYLES */
  .indexCalendarContent {
    .indexHeader {
      img {
        opacity: 0;
      }
      p {
        font-size: 20px !important;
      }
    }
    .indexBody {
      p {
        width: 90% !important;
        font-size: 16px !important;
        line-height: 22px !important;
      }
    }
  }

  /* MAIN SLIDE STYLES */
  .calendarContent {
    border: 1px solid #FFF;
    z-index: 10;
    .header {
      img {
        opacity: 0;
      }
      div {
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        padding: 5px 10px;
        position: relative;
        top: -1px;
        left: 1px;
        text-align: center;
        p {
          font-size: 14px;
          line-height: 26px;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }
        h2 {
          margin-top: -10px;
          font-size: 25px;
          line-height: 30px;
        }
      }
    }
    .body {
      p {
        width: 90%;
        font-size: 18px;
        line-height: 20px;
      }
    }
    .footer {
      p {
        font-size: 14px;
        line-height: 18px;
      }
      p:first-of-type {
        margin-top: 15px;
      }
      p:last-of-type {
        margin-bottom: 15px;
      }
      h3 {
        font-size: 32px;
        line-height: 63px;
        margin: 0;
      }
    }
  }
  /* END SLIDE STYLES */
  .endSlide {
    .contentContainer {
      width: 80%;
      > img {
        width: 70% !important;
      }
      p {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  /* SHARE FOOTER STYLES */
  .shareFooter {
    padding-top: 0;
    p {
      margin: 20px auto;
      width: 85%;
      font-size: 11px;
      line-height: 16px;
    }
  }
}

.indexCopy {
  color: rgba(0, 0, 0, 0.65) !important;
}

@media (max-width: 415px) {
  .slideButton {
    margin-top: 20px;
    margin-bottom: 10px;
    p {
      font-size: 10px !important;
    }
  }
  /* SHARED CALENDAR CONTENT */
  .indexCalendarContent, .calendarContent {
    margin: 2px !important;
  }
  /* INDEX SLIDE STYLES */
  .indexCalendarContent {
    .indexHeader {
      img {
        height: 1px !important;
      }
      p {
        font-size: 16px !important;
      }
    }
    .indexFooter {
      a {
        button {
          margin-bottom: 15px !important;
        }
      }
    }
  }
  /* MAIN SLIDE STYLES */
  .calendarContent {
    .footer {
      p {
        font-size: 13px;
      }
      h3 {
        font-size: 26px;
        line-height: 50px;
      }
    }
  }
  /* END SLIDE STYLES */
  .endSlide {
    .contentContainer {
      width: 70%;
      > img {
        width: 90% !important;
      }
    }
  }
  /* MODAL STYLES */
  .modalWrapper {
    .modalContainer {
      width: 90%;
      .modalTop {
        h3 {
          font-size: 16px;
        }
        .equationContainer {
          font-size: 14px;
          > div {
            div {
              width: 125px !important;
            }
          }
          span {
            margin: 0 15px;
          }
        }
      }
      .modalBottom {
        p {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 330px) {
  .calendarContent, .indexCalendarContent {
    .header, .indexHeader {
      img {
        height: 1px;
      }
      p {
        font-size: 16px !important;
      }
    }
    .body, .indexBody {
      p {
        font-size: 16px;
      }
    }
    .footer, .indexFooter {
      h3 {
        margin: -5px 0;
        font-size: 20px;
      }
    }
  }
  /* END SLIDE STYLES */
  .endSlide {
    .contentContainer {
      width: 80%;
      > img {
        width: 90% !important;
      }
    }
  }
   /* MODAL STYLES */
   .modalWrapper {
    .modalContainer {
      width: 95%;
      .modalTop {
        h3 {
          font-size: 16px;
        }
        .equationContainer {
          font-size: 14px;
          > div {
            div {
              width: 100px;
            }
          }
          span {
            margin: 0 15px;
          }
        }
      }
      .modalBottom {
        p {
          font-size: 12px;
        }
      }
    }
  }
}

/* MOBILE LANDSCAPE STYLES */
@media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) {
  .calendar {
    height: auto;
  }

  .calendarContent {
    .body {
      p {
        margin: 50px auto;
        width: 90% !important;
      }
    }
    .footer {
      p {
        font-size: 10px;
      }
    }
  }
    /* SHARE FOOTER STYLES */
    .shareFooter {
      p {
        margin: 20px auto;
        width: 85%;
        font-size: 9px;
        line-height: 12px;
      }
      a {
        button {
          margin-bottom: 15px;
        }
      }
    }
}