@import "../../App.scss";

.pageWrapper {
  z-index: 5;
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
}

.introContainer {
  @extend .absoluteCenter;
  z-index: 10;
  color: white;
  text-align: center;
  h1 {
    margin-bottom: 60px;
  }
  img {
    height: 100px;
    margin-bottom: 75px;
  }
  p {
    font-size: 20px;
    margin: 0;
    font-family: 'Graphik Regular';
    line-height: 28px;
  }
  p:first-of-type {
    margin-bottom: 25px;
  }
  span {
    @extend .drukWideRegular;
    cursor: pointer;
    width: 280px;
    height: 50px;
    border-radius: 33px;;
    background-color: #FFF;
    text-decoration: none;
    color: #000;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    margin-top: 63px !important;
    text-transform: uppercase;;
  }
}

@media (max-width: 600px) {
  .introContainer {
    p {
      br {
        display: none;
      }
    }
  }
}

@media (max-width: 450px) {
  .introContainer {
    width: 80%;
    img {
      width: 80%;
    }
    p {
      font-size: 18px;
      line-height: 22px;
    }
    span {
      width: 100%;
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
  .pageWrapper, .videoWrapper {
    height: 600px;
  }
  .introContainer {
    margin: 125px 0 50px;
  }
}