@import "../../App.scss";

.statementContainer {
  // HERO STYLES
  .heroContainer {
    @extend .flex, .drukWideRegular;
    position: relative;
    justify-content: center;
    align-items: center;
    div {
      position: absolute;
      flex-direction: column;
      p {
        font-size: 18px;
        line-height: 23px;
        color: #000000;
      }
      h3 {
        font-size: 60px;
        line-height: 78px;
        color: #E95656;
      }
    }
    img {
      height: 400px;
    }
  }
  .copyContainer {
    @extend .contentWidth, .drukWideRegular;
    margin: 0 auto;
    h4 {
      font-weight: 100 !important;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: #000000;
    }
    > p {
      font-family: 'Graphik Regular';
      font-size: 18px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.65);
      margin: 25px 0;
      span {
        font-size: 10px;
        margin-right: 5px;
        margin-left: -3px;
        position: relative;
        top: -10px;
      }
    }
    .citationsContainer {
      border-top: 1px solid #EBEBEB;
      padding-top: 37px;
      a {
        cursor: pointer;
        display: block;
        text-decoration: underline;
        margin-bottom: 10px;
        font-family: 'Graphik Regular';
        font-size: 14px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

/* SCROLL STYLES */

.scrollContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  img {
    position: relative;
    top: 3px;
    height: 10px;
  }
}

@media (max-width: 600px) {
  .statementContainer {
    margin-top: 0;
    // HERO STYLES
    .heroContainer {
      div {
        text-align: center;
        p {
          font-size: 10px;
          line-height: 13px;
        }
        h3 {
          font-size: 35px;
          line-height: 39px;
        }
      }
      img {
        width: 100%;
        margin: -70px 0;
      }
    }
    .copyContainer {
      h4 {
        font-size: 14px;
        line-height: 22px;
      }
      > p {
        font-size: 14px;
        line-height: 22px;
      }
      .citationsContainer {
        a {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}

/* MOBILE LANDSCAPE STYLES */
@media screen and (min-width: 320px) and (max-width: 850px) and (orientation: landscape) {
  .scrollContainer {
    margin-top: 30px;
  }
}